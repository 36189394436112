import React from 'react'
import { Layout } from '../../components/layout'
import Meta from '../../components/layout/meta'
import ThankYouBlock from '../../components/pages/bedankt/thankYouBlock'
import { reviewStructuredData } from '../../components/layout/meta/schema-org'

class ThankYouPage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            download: '',
        }
    }

    componentDidMount() {
        // Get URL query params
        const search = new URLSearchParams(this.props.location.search)
        const link = search.get('dl')

        if (link !== null || link !== undefined) {
            this.setState({ download: link })
        }
    }

    render() {
        return (
            <Layout>
                <Meta
                    keywords={['tellow', 'bedankt', 'zzp', 'whitepaper', 'ebook']}
                    title="Bedankt! - Tellow"
                    description="Bedankt namens Tellow!"
                    path="/bedankt/starters"
                    jsonLD={reviewStructuredData}
                />
                <ThankYouBlock context="starter" props={this.state.download} />
            </Layout>
        )
    }
}

export default ThankYouPage
